import React, { useState } from "react";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-orange-400 text-4xl animate-spin border-blue-300 flex items-center justify-center border-t-orange-400 rounded-full"></div>
      </div>
    </section>
  ) : (
    <>
      <section className="p-6 bg-orange-100 border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>Allure Academy Online!</b>
        </p>
        <p className="my-3">
          At Allure Academy Online, we are dedicated to providing top-notch online education that caters to the diverse needs of learners worldwide. Our mission is to empower individuals with the knowledge, skills, and confidence they need to succeed in their personal and professional lives.
        </p>
        <h2 className="text-xl font-bold my-3">Our Story</h2>
        <p className="my-3">
          Founded in 2024, Allure Academy Online was established with a vision to revolutionize the way education is delivered and accessed. We believe that learning should be accessible, flexible, and tailored to each individual's needs. Our platform is designed to provide high-quality educational resources that can be accessed from anywhere in the world.
        </p>
        <h2 className="text-xl font-bold my-3">Our Team</h2>
        <p className="my-3">
          Our team consists of experienced educators, industry experts, and technologists who are passionate about education. We work collaboratively to create engaging, interactive, and effective learning experiences for our students. Each team member brings a wealth of knowledge and expertise to ensure that our courses meet the highest standards of quality.
        </p>
        <h2 className="text-xl font-bold my-3">Our Services</h2>
        <p className="my-3"><b>Online Courses:</b> We offer a wide range of courses across various subjects, designed to cater to different learning levels and interests.</p>
        <p className="my-3"><b>Personalized Learning:</b> Our platform provides personalized learning paths to help students achieve their unique goals.</p>
        <p className="my-3"><b>Expert Instructors:</b> Learn from industry leaders and experts who bring real-world experience and insights to the classroom.</p>
        <p className="my-3"><b>Community Support:</b> Join a community of like-minded learners and educators to collaborate, share knowledge, and grow together.</p>
        <p className="my-3">Thank you for choosing Allure Academy Online as your educational partner. We are committed to supporting your learning journey and helping you achieve your goals.</p>
      </section>
    </>
  );
}
