import React, { useEffect, useState } from "react";
import Games from "../Games.json";
import GameCard from "./GameCard";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-orange-400 text-4xl animate-spin border-blue-300 flex items-center justify-center border-t-orange-400 rounded-full"></div>
      </div>
    </section>
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6 w-full">
        {randomGames.map((data, index) => (
          <GameCard key={index} data={data} />
        ))}
      </div>
    </section>
  );
}
