import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gradient-to-r from-orange-600 via-orange-700 to-orange-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 text-white p-2 container mx-auto my-1 rounded-lg text-center">
      <p>
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            Allure academy
          </Link>
        </span>
      </p>
    </footer>
  );
}
