import React, { useState } from "react";

export default function PrivacyPolicy() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-orange-400 text-4xl animate-spin border-blue-300 flex items-center justify-center border-t-orange-400 rounded-full"></div>
      </div>
    </section>
  ) : (
    <>
      <section className="p-6 bg-orange-100 border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">Privacy Policy</h1>
        <p className="my-3">
          This privacy policy explains how <b>Allure Academy Online</b> collects, uses, and protects any information that you provide when you use this website.
        </p>

        <h6 className="font-bold mb-3 mt-6">1. Information We Collect</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Personal Information:</b> We may collect personal information such as your name, email address, and payment details when you register for courses or make a purchase.
          </li>
          <li className="my-2">
            <b>Non-Personal Information:</b> We also collect non-personal information such as your browser type, IP address, and browsing behavior on our website.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">2. How We Use Your Information</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">To provide and improve our services.</li>
          <li className="my-2">
            To process transactions and send periodic emails regarding your order or other products and services.
          </li>
          <li className="my-2">
            To improve our website based on the information and feedback we receive from you.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">3. Information Protection</h6>
        <p className="my-3">
          We implement a variety of security measures to maintain the safety of your personal information. All transactions are processed through a secure payment gateway provider and are not stored or processed on our servers.
        </p>

        <h6 className="font-bold mb-3 mt-6">4. Cookies</h6>
        <p className="my-3">
          We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interactions.
        </p>

        <h6 className="font-bold mb-3 mt-6">5. Third-Party Disclosure</h6>
        <p className="my-3">
          We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
        </p>

        <h6 className="font-bold mb-3 mt-6">6. Your Consent</h6>
        <p className="my-3">
          By using our site, you consent to our website privacy policy.
        </p>

        <h6 className="font-bold mb-3 mt-6">7. Changes to Our Privacy Policy</h6>
        <p className="my-3">
          We reserve the right to update or change our privacy policy at any time. Any changes will be posted on this page.
        </p>

        <h6 className="font-bold mb-3 mt-6">8. Contact Us</h6>
        <p className="my-3">
          If you have any questions regarding our terms and conditions, about us, or privacy policy, please contact us at support@allureacademy.online.
        </p>
      </section>
    </>
  );
}
