import React, { useState } from "react";

export default function TermCondition() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);
  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="flex-col gap-4 w-full flex items-center justify-center">
        <div className="w-28 h-28 border-8 text-orange-400 text-4xl animate-spin border-blue-300 flex items-center justify-center border-t-orange-400 rounded-full"></div>
      </div>
    </section>
  ) : (
    <>
      <section className="p-6 bg-orange-100 border border-gray-200 rounded-lg shadow my-3">
        <h1 className="text-2xl font-bold text-center">Terms and Conditions</h1>

        <h6 className="font-bold mb-3 mt-6">1. Introduction</h6>
        <p className="my-3">
          Welcome to Allure Academy Online. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions. If you disagree with any part of these terms, please do not use our website.
        </p>

        <h6 className="font-bold mb-3 mt-6">2. Use of Website</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            You agree to use the website only for lawful purposes and in a manner that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website.
          </li>
          <li className="my-2">
            You must not use the website to transmit any unsolicited or unauthorized advertising, promotional materials, or spam.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">3. Intellectual Property</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            All content on this website, including but not limited to text, graphics, logos, and images, is the property of Allure Academy Online or its content suppliers and is protected by international copyright laws.
          </li>
          <li className="my-2">
            You may not reproduce, duplicate, copy, sell, resell, or exploit any portion of the website without express written permission from us.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">4. User Accounts</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.
          </li>
          <li className="my-2">
            You agree to accept responsibility for all activities that occur under your account or password.
          </li>
        </ul>

        <h6 className="font-bold mb-3 mt-6">5. Termination</h6>
        <p className="my-3">
          We reserve the right to terminate or suspend access to our website immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the terms.
        </p>

        <h6 className="font-bold mb-3 mt-6">6. Governing Law</h6>
        <p className="my-3">
          These terms shall be governed and construed in accordance with the laws of our country, without regard to its conflict of law provisions.
        </p>

        <h6 className="font-bold mb-3 mt-6">7. Changes to Terms</h6>
        <p className="my-3">
          We reserve the right, at our sole discretion, to modify or replace these terms at any time. Your continued use of the website following the posting of any changes to the terms constitutes acceptance of those changes.
        </p>
      </section>
    </>
  );
}
